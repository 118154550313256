.topbar {
    height: 7rem;
    padding: 0 0 .3rem 0;
    width: 100%;
    color: rgb(150, 100, 78);
    background-color: #fff;
    img {
        padding: 0px;
        width: 45%;
    }
    a:hover {
        color: darken(rgb(150, 100, 78), 20%);
        transition: color .5s;
    }
    .shrink {
        flex: 0 0 auto;
        max-width: 100%;
    }
}

// Hamburger Button und Off-Canvas

.hamburgers{
    z-index: 99;
    position: fixed;
    top: 0.2rem;
    right: 1rem;
    cursor: pointer;
    background: 0 0;
}

.closebox {
    height: 4.5rem;
    background-color: #E5B46F;
}

.small-topbar {
    height: 7rem;
    img {
        width: 360px;
    }
}

.off-canvas {
    background-color: #f4f4f4;
}