footer {
    background-color: #E5B46F;
    padding: 1.5rem;
    color: rgb(150, 100, 78);
    -webkit-box-shadow: 0px 0px 7px 0px rgba(128,128,128,1);
    -moz-box-shadow: 0px 0px 7px 0px rgba(128,128,128,1);
    box-shadow: 0px 0px 7px 0px rgba(128,128,128,1);
}

ul {
    list-style: none;
}