.back-to-top {
    background:#FFFBDB;
    position: fixed;
    bottom:0;
    right:20px;
    padding: 1rem;
    transition: background-color .5s;
    border: 1px solid #909090;
}

.back-to-top:hover {
    color: darken(rgb(150, 100, 78), 20%);
    background: scale-color(#FFFBDB, $lightness: -15%);
}
