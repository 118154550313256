.orbit-container {
    height: calc(100vh - 7rem);
    width: 100%;
 }

 .orbit-figure, .orbit-slide {
    width: 100%;
    height: 100%;
 }

.orbit-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.orbit-text {
    font-family: 'Merienda One', cursive;
    text-align: center;
    font-size: 2.5em;
    position: absolute;
    bottom: 20%;
    width: 100%;
    margin-bottom: 0;
    padding: 1rem;
    color: black;
}