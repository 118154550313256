
.d-mask {
    .button {
        padding: 0 !important;
    }

    a {
        position: relative;
        color: #535353 !important;
        border: 1px solid #b3b3b3 !important;
    }

    .d-img {
        display: block;
    }

    .d-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        transition: .5s ease;
        background-color: rgba(150, 100, 78, .7);
    }

    a:hover .d-overlay {
        visibility: visible;
        opacity: 1;
    }

    .d-overlay span {
        color: #fffbdb;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}