//Colors
$background: #fefefe;
$text: #4b507a;

$gray: #ffffff;
$cyan: #60daaa;
$yellow: #FFFBDB;
$orange: #E5B46F;
$dark-orange: rgb(150, 100, 78);

// Basic styles
nav li a{
    background: none;
    border: 0;
    box-sizing: border-box;
    margin: 1em;
    padding: 1em 2em;
    
    // Using inset box-shadow instead of border for sizing simplicity
    box-shadow: inset 0 0 0 2px $gray;
    color: $orange;
    font-size: inherit;
    font-weight: 700;
  
    // Required, since we're setting absolute on pseudo-elements
    position: relative;
    vertical-align: middle;
  
    &::before,
    &::after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  
  .draw {
      transition: color 0.25s;
  
    &::before,
    &::after {
      // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
      border: 2px solid transparent;
      width: 0;
      height: 0;
    }
  
    // This covers the top & right borders (expands right, then down)
    &::before {
      top: 0;
      left: 0;
    }
  
    // And this the bottom & left borders (expands left, then up)
    &::after {
      bottom: 0;
      right: 0;
    }
    
    &:hover {
      color: $cyan;
    }
  
    // Hover styles
    &:hover::before,
    &:hover::after {
      width: 100%;
      height: 100%;
    }
  
    &:hover::before {
      border-top-color: $cyan; // Make borders visible
      border-right-color: $cyan;
      transition:
        width 0.25s ease-out, // Width expands first
        height 0.25s ease-out 0.25s; // And then height
    }
  
    &:hover::after {
      border-bottom-color: $cyan; // Make borders visible
      border-left-color: $cyan;
      transition:
        border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
        width 0.25s ease-out 0.5s, // And then exanding width
        height 0.25s ease-out 0.75s; // And finally height
    }
  }
  
  // Inherits from .draw
  .meet {
    
    &:hover {
      color: $orange;
    }
  
    // Start ::after in same position as ::before
    &::after {
      top: 0;
      left: 0;
    }
  
    // Change colors
    &:hover::before {
      border-top-color: $orange;
      border-right-color: $orange;
    }
  
    &:hover::after {
      border-bottom-color: $orange;
      border-left-color: $orange;
      transition: // Animate height first, then width
        height 0.25s ease-out,
        width 0.25s ease-out 0.25s;
    }
  
  }
  