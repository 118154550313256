.leistungen {
    padding: 3rem;
}

.callout {
    padding: 0;
    position: relative;
    border: 1px solid #dbdbdb;
    img {
        width: 100%;
    }
    p, h3 {
        padding: 1rem;
        position: absolute;
        bottom: .5rem;
        left: .5rem;
        color: #303030;
        @include breakpoint(large down) {
            position: static;
            bottom: auto;
            left: auto;
            margin-top: 1rem;
        }
    }
}