.mb-md {
    margin-bottom: 2rem;
}

.mb-lg {
    margin-bottom: 3rem;
}

.full-width {
    width: 100%;
    margin: auto;
    max-width: initial;
    padding: 0px;
    .columns {
        padding: 0px;
    }
}

h1, h2, h3 {
    font-family: 'Merienda One';
}


h2 {
    padding-bottom: 2rem;
}

h3 {
    color: #5e5e5e;
    p {
        font-family: 'Amethysta';
    }
}

p {
    font-family: 'Amethysta';
    color: #404040;
}

a {
    color: rgb(150, 100, 78);
    text-decoration: none;
}

a:hover {
    color: darken(rgb(150, 100, 78), 20%);
}

a:focus {
    color: darken(rgb(150, 100, 78), 20%);
}

section, .section {
    padding: 2rem 0;
    @include breakpoint(xlarge) {
        padding: 5rem 0rem;
    }
}

.dark-orange {
    background-color: #E5B46F !important;
}

.light-orange {
    background-color: #fffbdb !important;
}