.cookies {
    padding: 1rem;
    color: #f4f4f4;
    background-color: #E5B46F;
    border: 1px solid #fefefe;
    width: 100%;
    max-width: 75rem;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-box-shadow: 1px 1px 3rem rgba(11, 23, 34, .2);
    box-shadow: 1px 1px 3rem rgba(11, 23, 34, .2);
    z-index: 999
}
    